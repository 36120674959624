export default {
  get tracker() {
    if (typeof window === 'undefined') {
      return null;
    }
    if (window._paq) {
      return window._paq;
    } else {
      return (window._paq = []);
    }
  },

  track(...args) {
    let tracker = this.tracker;

    if (tracker) {
      tracker.push(args);
    }
  },

  trackEvent(...args) {
    this.track("trackEvent", ...args);
  },

  trackSearch(term) {
    this.track("trackSiteSearch", term);
  },

  trackSelectedRifugio(name) {
    this.trackEvent("Selected Rifugio", name);
  },

  trackClickLink(name) {
    this.trackEvent("Link click", name);
  },

  trackSocialShare(network, sourcePage) {
    this.trackEvent("Social Share", "Social Share From " + sourcePage, network);
  },

  trackDonate(type, source) {
    this.trackEvent("Donation Click", type, "Donation From " + source);
  },

  trackApproveRifugio() {
    this.trackEvent("Approve Rifugio");
  },

  trackRegisterButtonLinkClick(device) {
    this.trackEvent("Register Click", "Register From " + device);
  },
}