import { FormattedMessage } from "react-intl";
import Typography from "@mui/material/Typography";
import { container } from "../../styles/layout";
import withStyles from '@mui/styles/withStyles';
import Link from 'next/link'

const styles = (theme) => ({
  root: {
    ...container,
  },
  inner: {
    // borderTop: "2px solid #00AE8D",
    marginTop: 30,
    paddingTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.up("md")]: {
      paddingRight: 30,
      paddingLeft: 30,
    },
  },
  innerLeft: {
    // borderTop: "2px solid #00AE8D",
    width: "25%",
    float: "left",
    marginBottom: 30,
    [theme.breakpoints.down('md')]: {
      width: "100%",
      textAlign: "center",
    },
  },
  innerRight: {
    // borderTop: "2px solid #00AE8D",
    width: "70%",
    float: "right",
    marginBottom: 60,
    fontSize: '14px',
    fontWeight: "400",
    [theme.breakpoints.down('md')]: {
      width: "100%",
      fontSize: '10px',
    },
  },
  Copyright: {
    textAlign: "center",
  },
  DisclaimerLink: {
    color: theme.palette.primary.main,
    fontWeight: "600",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  VercelLogo: {
    height: "2.4em",
    marginTop: "5px",
  }
});

const PoweredBy = (
  <FormattedMessage id="footer.poweredBy" defaultMessage="Powered by Vercel" />
);

const DisclaimerText = (
  <FormattedMessage
    id="footer.disclaimer"
    defaultMessage="<span>Disclaimer:</span> Questo servizio viene fornito così com'è, senza garanzie di alcun tipo. L'utilizzo di questo servizio è interamente a tuo rischio. Non possiamo assumerci la responsabilità per eventuali danni diretti o indiretti derivanti dall'utilizzo di questo servizio. Le informazioni fornite da questo servizio, insieme al contenuto del nostro sito Web relativo a questioni legali, sono fornite per uso privato e non costituiscono consulenza legale."
    values={{
      span: txt => (<span style={{ fontWeight: "bold", color: "black" }}>{txt}</span>)
    }}
  />
);

const Footer = ({ classes, showRoadmap=true }) => {
  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <div className={classes.innerLeft}>
          <Typography component="p" variant="subtitle2">
            <FormattedMessage
              id="footer.madeBy"
              defaultMessage="<a>Made by Yoav Aviram / Conscious Digital</a>"
              values={{
                a: txt=> (<a target="_blank" href="https://consciousdigital.org" className={classes.DisclaimerLink}>{txt}</a>)
              }}
            />
          </Typography>          
          <Typography component="p" variant="subtitle2">
            <FormattedMessage
              id="footer.privacyPolicy"
              defaultMessage="<a>Privacy</a>"
              values={{
                a: txt=> (<Link href="/privacy" className={classes.DisclaimerLink}>{txt}</Link>)
              }}
            />
          </Typography>
          <a            
            href="https://vercel.com?utm_source=Conscious%20Digital&utm_campaign=oss"
          >
            <img 
              src="/images/powered-by-vercel.svg" 
              alt={PoweredBy}
              className={classes.VercelLogo}
            />
          </a>
        </div>
        <div className={classes.innerRight}>
          <Typography gutterBottom={true} color="textSecondary" variant="subtitle2">
            {DisclaimerText}
            <br/>
            <FormattedMessage
              id="footer.copyright"
              defaultMessage="© Copyright 2022 - 2023. Image of Monte Amiata by <a>Scott Williams</a>."
              values={{
                a: txt=> (<a target="_blank" href="https://commons.wikimedia.org/wiki/File:Val_D%27Orcia1.jpg" className={classes.DisclaimerLink}>{txt}</a>)
              }}
            />
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Footer);
